<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    label-width="80px"
    class="demo-ruleForm"
  >
    <el-form-item label="名称：" prop="name">
      <el-input
        v-model="ruleForm.name"
        placeholder="请输入名称"
        @input="handleOnKeyup('name')"
      ></el-input>
    </el-form-item>
    <el-form-item label="url：" prop="url">
      <el-input
        v-model="ruleForm.url"
        placeholder="请输入url"
        @input="handleOnKeyup('url')"
      ></el-input>
    </el-form-item>
    <el-form-item label="说明" prop="remark">
      <el-input
        v-model="ruleForm.remark"
        placeholder="请输入说明"
        @input="handleOnKeyup('remark')"
      ></el-input>
    </el-form-item>
    <div class="btn-boxs">
      <el-button class="btns" type="primary" @click="submitForm('ruleForm')"
        >确定</el-button
      >
      <el-button @click="handleClose">取消</el-button>
    </div>
  </el-form>
</template>

<script>
export default {
  props: {
    handleClose: Function,
    getPermissionInsert: Function,
    paramsObj: Object,
    dialogType: Number,
  },
  name: "AddAuth",
  data() {
    return {
      ruleForm: {
        type: 0,
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        url: [{ required: true, message: "请输入url", trigger: "blur" }],
        remark: [{ required: true, message: "请输入说明", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleOnKeyup(type) {
      console.log(type);
      this.ruleForm[type] = this.handleKeyup(this.ruleForm[type]);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getPermissionInsert({
            ...this.ruleForm,
            parentId: this.dialogType === 1 ? 0 : this.paramsObj.id,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
